@font-face {
    font-family: 'aa';
    src: url('../fonts/aa.woff2') format('woff2'),
         url('../fonts/aa.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.brand-blue {color:#00FFFF;}
.brand-yellow {color:#EDCC38;}
.brand-green {color:#39AA4A;}
.brand-pink {color:#DF0080;}

.profit-green {color:#48a600;}
.error {color: #bb0000;}

.text-gray {color: #a3a3a3;}

body {
    margin:0;
    padding:0;
    background-color: #161828;
}

h1, h2, h3, h4, h5, h6 {font-family: "aa"; font-weight: 200;}

h2 {font-size: 56px;}
@media (max-width: 767px) {
    h2 {font-size: 36px;}
}

h3 {font-size: 28px;}
@media (max-width: 767px) {
    h3 {font-size: 24px;}
}

h4 {font-size: 24px;}
@media (max-width: 767px) {
    h4 {font-size: 22px;}
}

h5 {font-size: 20px;}
@media (max-width: 767px) {
    h5 {font-size: 18px;}
}




p {
    font-size: 16px;
    letter-spacing: 1px;
}
@media (max-width: 575px) {
    p {font-size: 14px;}
}

.small-font {
    font-size: 16px;
}


.fluid-padding {
    padding-left: 25px;
    padding-right: 25px;
}
@media (min-width: 1200px) {
    .fluid-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (min-width: 1400px) {
    .fluid-padding {
        padding-left: 200px;
        padding-right: 200px;
    }
}
@media (min-width: 1700px) {
    .fluid-padding {
        padding-left: 320px;
        padding-right: 320px;
    }
}



button:focus {
    box-shadow: none !important;
}


.desktop-sm {
    display: block;
}
@media (max-width: 575px) {
    .desktop-sm {
        display: none;
    }
}
.mobile-sm {
    display: none;
}
@media (max-width: 575px) {
    .mobile-sm {
        display: block;
    }
}
.desktop-md {
    display: block;
}
@media (max-width: 767px) {
    .desktop-md {
        display: none;
    }
}
.mobile-md {
    display: none;
}
@media (max-width: 767px) {
    .mobile-md {
        display: block;
    }
}
.desktop-lg {
    display: block;
}
@media (max-width: 991px) {
    .desktop-lg {
        display: none;
    }
}
.mobile-lg {
    display: none;
}
@media (max-width: 991px) {
    .mobile-lg {
        display: block;
    }
}

.flip-countdown-title {
    color: #ececec !important;
}
