/* General Styling */
.mobile-view {
  width: 475px;
  overflow: hidden;
}

.main-logo {
  width: 100%;
  max-width: 250px;
  margin-top: 150px;
}


.login-bg {
  background-image: url('../img/login-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.custom-btn {
  width: 210px;
  height: 50px;
}
.custom-btn:hover {
  scale: 1.02;
  transition: 0.5s;
}

.custom-btn-text {
  margin-top: -36px;
}

.option-btn {
  width: 100%;
  border: 1px solid #00FFFF;
  border-radius: 0;
  background-color: transparent;
  padding: 5px 10px;
}

.option-btn-selected {
  border: 1px solid #01c9c9;
  background-color: #01c9c9;
}

.main-content {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-bottom: 150px;
}





.referral-area {
  padding: 80px 30px;
}
@media (max-width: 399px) {
  .referral-area {
    padding: 50px 15px 80px 15px;
  }
}



.header {
  background: #000;
  padding: 20px 15px;
}

.header-logo {
  width: 100%;
  max-width: 200px;
}










.data-box {
  background-color: #000000aa;
  border-radius: 10px;
  padding: 20px;
}

.data-container {
  
}

.data-text {
  width: 100%;
  max-width: 360px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 475px) {
  .data-text {
    max-width: 280px;
  }
}
@media (max-width: 399px) {
  .data-text {
    max-width: 230px;
    padding: 5px;
  }
}




/* Assets Page */
.token-icon {
  width: 45px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .token-icon {
    width: 35px;
  }
}




/* Invest Page */
.invest-modal {
  background-color: #000000ed;
  border-radius: 10px;
}

.table-container {
  background-color: #000000aa;
  border-radius: 10px;
  padding: 15px;
}

.details-table {
  overflow-x: auto;
}

.borderless th, .borderless td {
  border: none;
}

/* Register Page */
.register-bg {
  background-image: url('../img/blue-bg.jpg');
}


/* Team Page */
.team-bg {
  background-image: url("../img/blue-bg.jpg");
}


/* Profile Page */
.profile-bg {
  background-image: url("../img/blue-bg.jpg");
}


/* Mining Page */
.mining-bg {
  background-image: url("../img/mining-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.package-bg {
  background-image: url('../img/package-bg.jpg');
}


/* Market Page */
.market-bg {
  background-image: url("../img/blue-bg.jpg");
}

.market-box {
  background-color: #000000;
  border: 1px solid #00FFFF;
  padding: 40px 20px;
}

.wallet-balance-box {
  background-color: #000000;
  border: 1px solid #00FFFF;
  padding: 10px;
}

.wallet-balance-box p {
  font-size: 18px;
}

.rc-token-icon {
  width: 100%;
  max-width: 120px;
}


/* Bottom Menu */
.bottom-menu {
  background-color: #000;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 475px;
  padding: 10px 15px;
}

.bottom-menu-icon {
  width: 100%;
  max-width: 55px;
  font-size: 28px;

}

.icon-unselected {
  color: #00FFFF;
}

.icon-selected {
  color: #00b5ff;
}



input[type=number], input[type=number]:focus {
  background-color: #000000;
  border: 1px solid #00FFFF;
  padding: 20px;
  border-radius: 0;
  color: #FFF;
}


input[type=text], input[type=text]:focus {
  width: 100%;
  padding: 8px 10px;
  font-size: 16px;
  background-color: #F3F5F7;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
}

.divider {
  border-top: 1px solid rgb(171, 171, 171);
}

/* Language switch button */
.language-switch {
  position: relative;
  display: inline-block;
  margin: 10px;
}

#language-select {
  width: 100%;
  border: 2px solid #00FFFF;
  background-color: transparent;
  color: white;
  border-radius: 0;
  padding: 5px 0;
  cursor: pointer;
  text-align: center;
}

#language-select option {
  background-color: #00FFFF;
  color: black;
}

#language-select option:disabled{
  color: grey;
}

#language-select:focus {
  outline: none;
}

.embed-box {
  background-color: #000000aa;
  border-radius: 10px;
  padding: 10px;
}
.embed-shop {
  width: 100%;
  min-height: 70vh;
}



